import { Injectable } from '@angular/core';
import {StorageService} from '../../services/storage.service';
import {ApiService} from '../../services/api.service';
import {HttpClient} from '@angular/common/http';
import {ProfileModel} from '../../../models/profile/profile.model';
import {VehicleModel} from "../../../models/profile/vehicle.model";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
      private api: ApiService,
      private http: HttpClient,
      private storageService: StorageService,
  ) {}

  private prefixes = [32, 33, 352, 31, 1, 7, 20, 27, 30, 31, 34, 36, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
	  51, 52, 53, 54, 55, 56, 57, 58, 60, 61, 62, 63, 64, 65, 66, 81, 82, 84, 86, 90, 91, 94, 95, 98, 212, 213,
	  216, 218, 220, 221, 222, 223, 224, 226, 227, 228, 229, 231, 232, 233, 234, 236, 237, 238, 239, 240, 241,
	  242, 244, 245, 248, 249, 250, 251, 252, 253, 254, 256, 257, 258, 260, 261, 262, 263, 264, 265, 266, 267,
	  268, 269, 290, 291, 297, 298, 299, 350, 351, 353, 354, 356, 357, 358, 359, 370, 371, 372, 373, 374, 375,
	  376, 377, 378, 379, 380, 381, 385, 386, 387, 389, 417, 421, 500, 501, 502, 503, 504, 505, 506, 507, 509,
	  590, 591, 592, 593, 594, 595, 596, 597, 598, 670, 671, 672, 673, 674, 675, 676, 677, 678, 679, 680, 681,
	  682, 683, 686, 687, 688, 689, 691, 692, 850, 852, 853, 855, 856, 880, 886, 960, 961, 962, 963, 964, 965,
	  966, 967, 968, 969, 971, 972, 973, 974, 975, 976, 977, 993, 994, 996, 1242, 1246, 1264, 1268, 1345, 1441,
	  1473, 1649, 1664, 1758, 1787, 1809, 1809, 1868, 1869, 1876, 7880, 90392];

  public setCurrentProfile(profile: ProfileModel) {
    return this.http.put(
        this.api.getHostVersion() + '/organisation/' + this.storageService.getCurrentOrganisation() + '/profile/' + profile.id + '',
        profile,
        {headers: this.api.getHeaders()});
  }

  public updateVehicle(vehicle: VehicleModel) {
      return this.http.put(
          this.api.getHostVersion() + '/organisation/' + this.storageService.getCurrentOrganisation() + '/profile/me/vehicle/' + vehicle.vehicle_id,
          vehicle,
          {headers: this.api.getHeaders()});
  }

  public deletePhone(profile: ProfileModel, phoneId: number) {
    return this.http.delete(
        this.api.getHostVersion() + '/organisation/' + this.storageService.getCurrentOrganisation() + '/profile/' + profile.id + '/recognition-phone/' + phoneId,
        {headers: this.api.getHeaders()});
  }

  public deleteVehicle(profile: ProfileModel, vehicleId: number) {
    return this.http.delete(
        this.api.getHostVersion() + '/organisation/' + this.storageService.getCurrentOrganisation() + '/profile/me/vehicle/' + vehicleId,
        {headers: this.api.getHeaders()});
  }

  public getPrefixes() {
    return this.prefixes;
  }

}
