import {Component} from '@angular/core';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {TranslationService} from '../../services/translation.service';
import {BookingService} from '../../tabs/services/calendar/booking.service';
import {BesaasResponse} from '../../services/api.service';
import {ErrorManagerServiceService} from '../../services/errorManagerService.service';
import {TimespotslotavailabilityModel} from '../../../models/calendar/timespotslotavailability.model';
import {LoaderService} from '../../services/loader.service';
import {TimespotslotsavailabilityModel} from '../../../models/calendar/timespotslotsavailability.model';
import {NewBookingAvailabilityPage} from '../new-booking-availability/new-booking-availability.page';
import {DateService} from '../../services/date.service';
import {BookingForm} from './BookingForm';
import {BookableAccessesModel} from '../../../models/access/bookableAccesses.model';
import {BookableAccessModel} from '../../../models/access/bookableAccess.model';

@Component({
    selector: 'app-new-booking',
    templateUrl: './new-booking.page.html',
    styleUrls: ['./new-booking.page.scss'],
})
export class NewBookingPage {

    translations = {
        title: '',
        accessService: '',
        ok: '',
        cancel: '',
        error_no_time_available: '',
        booking_for_date: '',
        credit_left: '',
        no_spot_bookable: '',
        no_spot_bookable_more: '',
        save: '',
        error_from_invalid: '',
        start: '',
        end: '',
        allDay: '',
        day_limit_restriction_or_no_access: '',
        selected_hour_not_available: '',
        button_check_availability: ''
    };

    showLoader = false;

    pivotDate: Date = new Date();
    accesses: BookableAccessesModel;
    selectedAccess: BookableAccessModel;
    timeslostsAvailability: TimespotslotsavailabilityModel = new TimespotslotsavailabilityModel();

    form = new BookingForm();

    constructor(
        private modalController: ModalController,
        private navParams: NavParams,
        private translationService: TranslationService,
        private alertController: AlertController,
        private bookingService: BookingService,
        private errorManagerServiceService: ErrorManagerServiceService,
        private dateService: DateService,
        private loaderService: LoaderService
    ) {
        this.accesses = navParams.get('accesses');
        this.pivotDate = new Date(navParams.get('currentDate'));
    }

    public getValidAccesses() {

        let loopAccess: BookableAccessModel;
        const accesses: Array<BookableAccessModel> = [];

        for (let i = 0, len = this.accesses.accesses.length; i < len; i++) {
            loopAccess = this.accesses.accesses[i];

            if (loopAccess.planning_period.valueOf() > this.pivotDate.valueOf()) {
                accesses.push(loopAccess);
            }
        }

        return accesses;
    }

    async presentAlert(message, header = '', subHeader = '') {
        const alert = await this.alertController.create({
            header: header,
            subHeader: subHeader,
            message: message,
            buttons: [this.translations.ok]
        });

        await alert.present();
    }

    ionViewDidEnter(): void {
        this.translationService.loadTranslation('newbooking', this.translations);
    }

    public onAccessSelected() {
        this.showLoader = true;
        this.form.reset();
        this.selectedAccess = this.getAccessById(this.form.accessServiceId);

        this.bookingService.getTimespanForAccessService(this.selectedAccess.access_service_id, this.pivotDate)
            .subscribe((response: BesaasResponse) => {
                this.showLoader = false;

                this.timeslostsAvailability.deserialize(response.data);

                this.form.selectedAccess = this.selectedAccess;
                this.form.allowByHour = this.selectedAccess.allowed_to_book_per_hour;

                let timeslot = new TimespotslotavailabilityModel();

                // If there is only one period on the day, from & to as well as min & max date are the values of this period.
                if (this.timeslostsAvailability.availability.length === 1) {

                    this.form.disableTo = false;

                    timeslot = this.timeslostsAvailability.availability[0];

                    this.form.minDateFrom = this.form.minDateTo = this.dateService.formatToTimeString(timeslot.from);
                    this.form.maxDateFrom = this.form.maxDateTo = this.dateService.formatToTimeString(timeslot.to);

                    this.form.from = this.dateService.formatToIso8601(timeslot.from, '00');
                    this.form.to = this.dateService.formatToIso8601(timeslot.to, '59');


                    this.form.allDayAllowed = this.dateService.format(this.form.to, 'HH:mm:ss') === '23:59:59'; // allowed only if there is only one continuous period during the full day not ending sooner than the end of the day.

                } else if (this.timeslostsAvailability.availability.length > 1) {
                    // otherwise we'll show only the from date and it's min and max date are the minimum & maximum value of all the timeframe

                    for (let i = 0, len = this.timeslostsAvailability.availability.length; i < len; i++) {
                        timeslot = this.timeslostsAvailability.availability[i];

                        if (i === 0) {
                            this.form.minDateFrom = this.form.minDateTo = this.dateService.formatToTimeString(timeslot.from);
                        }

                        if ( (i + 1) === this.timeslostsAvailability.availability.length) {
                            this.form.maxDateFrom = this.form.maxDateTo = this.dateService.formatToTimeString(timeslot.to);
                        }
                    }
                }

                if(this.form.from.length > 0 && this.form.to.length > 0)
                {
                    this.checkValidity();
                }

            }, (error) => {
                this.showLoader = false;

                if (error.status === 400 && error.error.data.error_code === 'no_time_available') {
                    this.presentAlert(this.translations.error_no_time_available);
                } else {
                    this.errorManagerServiceService.handle(error);
                }
            });
    }

    protected getAccessById(id): BookableAccessModel {
        for (let i = 0, len = this.accesses.accesses.length; i < len; i++) {

            if (this.accesses.accesses[i].access_service_id === parseInt(id, 10)) {
                return this.accesses.accesses[i];
            }
        }
    }

    public createBooking() {

        this.loaderService.showGateLoader();
        const from = new Date(this.form.from);
        let to = new Date(this.form.to);

        //If we are at 23:59, we make sure to be on the latest seconds to fully book the day
        if(to.getHours() === 23 && to.getMinutes() === 59)
        {
            to.setSeconds(59);
        }
        else
        {
            //In the other case, we stop the booking 1 seconds before the selected minutes. That allows to chain to booking more easily.
            to.setSeconds(0);
            to = new Date(to.valueOf() - 1000);
        }

        this.bookingService.postTimeSpotSLot(this.form.accessServiceId, from, to).subscribe((response: BesaasResponse) => {
            this.loaderService.hideGateLoader();
            this.dismissModal({done: true});
        }, (error) => {
            this.loaderService.hideGateLoader();
            this.errorManagerServiceService.handle(error);
        });
    }

    public onAllDaySelected() {
        this.checkValidity();

        if (this.form.allDay) { // can be done only if there is one period on the day. Let's setup min/max & default value to the only period.
            const timeslot = this.timeslostsAvailability.availability[0];

            this.form.minDateFrom = this.form.minDateTo = this.dateService.formatToTimeString(timeslot.from);
            this.form.maxDateFrom = this.form.maxDateTo = this.dateService.formatToTimeString(timeslot.to);

            this.form.from = this.dateService.formatToIso8601(timeslot.from, '00');
            this.form.to = this.dateService.formatToIso8601(timeslot.to, '59');
        }
    }

    public onFromSelected() {
        this.form.from = this.dateService.formatToIso8601(new Date(this.form.from).setDate(this.pivotDate.getDate()), '00');

        if (this.timeslostsAvailability.availability.length > 1) {
            const comparableFrom = new Date(this.form.from).valueOf();
            let isFromValid = false;
            let timeslot = new TimespotslotavailabilityModel();

            for (let i = 0, len = this.timeslostsAvailability.availability.length; i < len; i++) {
                timeslot = this.timeslostsAvailability.availability[i];

                const timeslotFromComparable = timeslot.from.valueOf();
                const timeslotToComparable = timeslot.to.valueOf();

                // check the period valid on the selected from and setup the to value.
                if (comparableFrom >= timeslotFromComparable && comparableFrom < timeslotToComparable) {
                    this.form.minDateTo = this.dateService.formatToTimeString(timeslot.from);
                    this.form.maxDateTo = this.dateService.formatToTimeString(timeslot.to);
                    isFromValid = true;
                    i = (this.timeslostsAvailability.availability.length + 1); // let's stop the loop then.
                }
            }

            if (!isFromValid) {
                this.form.disableTo = true;
                this.presentAlert(this.translations.error_from_invalid);
            }

            if(isFromValid && this.form.from !== this.form.to) {
                this.form.disableTo = false;
            }
        }

        if(this.form.from.length > 0 && this.form.to.length > 0)
        {
            this.checkValidity();
        }
    }

    public onToSelected() {
        this.form.to = this.dateService.formatToIso8601(new Date(this.form.to).setDate(this.pivotDate.getDate()), '00');
        this.checkValidity();
    }

    public checkValidity() {

        if (this.form.from.length > 0 && this.form.to.length > 0) {
            const fromComparable = this.dateService.getUnixTime(this.form.from);
            const toComparable = this.dateService.getUnixTime(this.form.to);
            this.form.isValidated = this.form.accessServiceId && toComparable > fromComparable;

            if (!this.isTimeFrameAvailable(this.dateService.formatToTimeString(this.form.from), this.dateService.formatToTimeString(this.form.to))) {
                this.form.isValidated = false;
                this.presentAlert(this.translations.selected_hour_not_available);
            }
        } else {
            this.form.isValidated = false;
        }
    }

    public dismissModal(data = {}) {
        this.modalController.dismiss(data);
    }

    public getPivotDateFormatted() {
        return this.dateService.format(this.pivotDate, 'dd/MM/y');
    }

    async showAvailabilityModal() {
        const modal = await this.modalController.create({
            component: NewBookingAvailabilityPage,
            componentProps: {
                timeslostsAvailability: this.timeslostsAvailability.availability,
                pivotDate: this.pivotDate
            }
        });

        modal.onWillDismiss().then( data => {
            data = data.data;
        });

        return await modal.present();
    }

    public isTimeFrameAvailable(from: string, to: string) {

        const tmpDateFrom = this.pivotDate;
        const tmpDateTo = this.pivotDate;

        const dateFrom = tmpDateFrom.setHours(parseInt(from.split(':')[0]), parseInt(from.split(':')[1]), 0);
        const dateto = tmpDateTo.setHours(parseInt(to.split(':')[0]), parseInt(to.split(':')[1]), 0);

        let timeslot = new TimespotslotavailabilityModel();

        for (let i = 0, len = this.timeslostsAvailability.availability.length; i < len; i++) {
            timeslot = this.timeslostsAvailability.availability[i];

            const timeSlotFromValue = timeslot.from.setSeconds(0).valueOf();
            const timeSlotToValue = timeslot.to.setSeconds(0).valueOf();

            // if the from - to provided is fully inside an available period, return true.
            if (dateFrom.valueOf() >= timeSlotFromValue && dateFrom.valueOf() <= timeSlotToValue && dateto.valueOf() >= timeSlotFromValue && dateto.valueOf() <= timeSlotToValue) {
                return true;
            }
        }

        return false;
    }

    public canBook()
    {
        return !this.cannotBook();
    }

    setCommunityIcon()
    {
        var parent = this;
        setTimeout(function(){
            const radios = document.getElementsByClassName('action-sheet-button-inner');
            let element;
            for (let index = 0; index < radios.length; index++) {
                element = radios[index];

                if(parent.accesses.accesses[index] && parent.accesses.accesses[index].community)
                {
                    element.innerHTML= '<ion-icon src="assets/icon/community.svg"></ion-icon>' + element.innerHTML;
                }
            }
        }, 1000);
    }

    private cannotBook()
    {
        const form = this.form;
        return this.getValidAccesses().length === 0 || form.selectedAccess !== null && (this.timeslostsAvailability.availability.length < 1 || (!form.allowByHour && !form.allDayAllowed && form.selectedAccess !== null));
    }
}
