import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NetworkService } from './services/network.service';
import {GeolocationService} from './services/geolocation.service';
import {ToastService} from './services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  isDesktop = false;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  constructor(
      private platform: Platform,
      private splashScreen: SplashScreen,
      private statusBar: StatusBar,
      private networkService: NetworkService,
      private toastService: ToastService,
      private geolocationService: GeolocationService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.geolocationService.watchPosition();

      this.networkService.startListeningOnConnection();

      this.platform.backButton.subscribe( () => {
        if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
          navigator['app'].exitApp();
        } else {
          this.toastService.presentToast('Press back again to exit');
          this.lastTimeBackPress = new Date().getTime();
        }
      });
    });
  }
}
