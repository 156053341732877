import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {StorageService} from '../../services/storage.service';
import {ApiService} from '../../services/api.service';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import {AlertController} from '@ionic/angular';
import {TranslationService} from '../../services/translation.service';
import {LoaderService} from '../../services/loader.service';

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  public scanSubscriber;
  public isQrEnabled = false;

  translations = {
    permission_missing_title: '',
    permission_missing_subtitle: '',
    permission_missing_txt: '',
    error: '',
    error_message: ''
  };

  constructor(
      private api: ApiService,
      private http: HttpClient,
      private storage: StorageService,
      private qrScanner: QRScanner,
      private alertController: AlertController,
      private translationService: TranslationService,
      private loaderService: LoaderService
  ) {
      this.translationService.loadTranslation('login_service', this.translations);
  }

  async presentAlert(header, subheader, message) {
    const alert = await this.alertController.create({
        header: header,
        subHeader: subheader,
        message: message,
        buttons: ['OK']
    });

    await alert.present();
  }

  public postLogin(loginForm, callbackSuccess, callbackError) {

    const oauthData = {
        username: loginForm.email,
        password: loginForm.password,
        grant_type: 'password',
        client_id: environment.apiClientId,
        client_secret: environment.apiClientSecret
    };

    return this.callOauthToken(oauthData, callbackSuccess, callbackError);
  }

  public logout() {
      this.storage.cleanOAuth();
  }

  public isLogged() {
      const hasBearer = this.storage.getBearer() !== null && this.storage.getBearer().length > 0;
      if (hasBearer) {
          this.refreshTokenIfNecessary();
      }
      return hasBearer;
  }

  private callOauthToken(oauthData, callbackSuccess?, callbackError?) {
      return this.http.post(this.api.getHost() + '/oauth/token', oauthData).subscribe((response) => {
          this.storage.storeOAuthResponse(response);
          if (callbackSuccess !== null) {
              callbackSuccess(response);
          }
      }, (response) => {
          if (callbackError !== null) {
              callbackError(response);
          }
      });
  }

  private refreshTokenIfNecessary() {
      if (
          this.storage.getTokenExpiration() === null
          ||
          (Number(this.storage.getTokenExpiration()) - Math.floor( (Number(Date.now()) / 1000) )) <= (86400 * 30)
      ) {

          const oauthData = {
              refresh_token: this.storage.getRefreshToken(),
              grant_type: 'refresh_token',
              client_id: environment.apiClientId,
              client_secret: environment.apiClientSecret
          };

          this.callOauthToken(oauthData, null, null);
      }
  }

  public loginWithQrCode(callbackSuccess, callbackError) {
      (window.document.querySelector('ion-app') as HTMLElement).classList.add('cameraView');
      this.qrScanner.prepare()
          .then((status: QRScannerStatus) => {
              if (status.authorized) {
                  // camera permission was granted
                  this.qrScanner.show();
                  this.isQrEnabled = true;
                  // start scanning
                  this.scanSubscriber = this.qrScanner.scan().subscribe((code: string) => {
                      this.closeQrLogin();
                      return this.loginWithCode(code, callbackSuccess, callbackError);
                  });

              } else if (status.denied) {
                  // camera permission was permanently denied, go to settings
                  this.presentAlert(
                    this.translations.permission_missing_title,
                    this.translations.permission_missing_subtitle,
                    this.translations.permission_missing_txt
                  ).then(() => {
                      this.qrScanner.openSettings();
                  });
              } else {
                  // permission was denied, but not permanently. You can ask for permission again at a later time.
                  this.presentAlert(
                      this.translations.permission_missing_title,
                      this.translations.permission_missing_subtitle,
                      this.translations.permission_missing_txt
                  ).then(() => {
                      this.qrScanner.openSettings();
                  });
              }
          })
          .catch((e: any) => {
            this.presentAlert(
              this.translations.error,
              '',
              this.translations.error_message + ' - ' + e.toString()
            );
          });
  }

    public loginWithCode(code: string, callbackSuccess, callbackError) {

        const codeData = code.split('|');

        const oauthData = {
            remember_token: codeData[1],
            user_id: codeData[0],
            grant_type: 'remember_token',
            client_id: environment.apiClientId,
            client_secret: environment.apiClientSecret
        };

        return this.callOauthToken(oauthData, callbackSuccess, callbackError);
    }

    public closeQrLogin() {
      (window.document.querySelector('ion-app') as HTMLElement).classList.remove('cameraView');
      this.qrScanner.hide();
      this.scanSubscriber.unsubscribe();
      this.qrScanner.destroy();
      this.isQrEnabled = false;
  }
}
