import { Injectable } from '@angular/core';
import {Geolocation, Geoposition} from '@ionic-native/geolocation/ngx';
import {Diagnostic} from '@ionic-native/diagnostic/ngx';
import {LocationAccuracy} from '@ionic-native/location-accuracy/ngx';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  constructor(
    private geolocation: Geolocation,
    private diagnostic: Diagnostic,
    private locationAccuracy: LocationAccuracy
  ) { }

  public checkLocalisationStatus(callbackSuccess, callbackError) {

    this.diagnostic.isLocationAuthorized().then( (authorized) => {

        if (authorized) {
            this.diagnostic.isLocationEnabled().then( (enabled) => {

                if (enabled) {
                    this.locationAccuracy.canRequest().then((canRequest: boolean) => {

                        if (canRequest) {
                            // the accuracy option will be ignored by iOS
                            this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
                                () => callbackSuccess(),
                                (error) => (callbackError !== null) ? callbackError('high_accuracy_issue', error) : null
                            );
                        } else {
                            callbackSuccess();
                        }

                    }, (error) => {
                        callbackError('high_accuracy_issue_2', error);
                    });
                } else {
                    if (callbackError !== null) {
                        callbackError('not_enabled');
                    }
                }

            });
        } else {
            this.diagnostic.getLocationAuthorizationStatus().then( (status) => {

                if (status === this.diagnostic.permissionStatus.DENIED_ALWAYS) {
                    if (callbackError !== null) {
                        callbackError('not_authorized');
                    }
                } else {
                    this.diagnostic.requestLocationAuthorization().then( (allowed) => {
                        // allowed value ANDROID: GRANTED, DENIED_ONCE, DENIED_ALWAYS
                        // allowed value IOS: denied_always, authorize_in_user

                        if (allowed !== this.diagnostic.permissionStatus.GRANTED && allowed !== this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
                            if (callbackError !== null) {
                                callbackError('not_authorized');
                            }
                        } else {
                            this.checkLocalisationStatus(callbackSuccess, callbackError);
                        }
                    }, (error) => {
                        if (callbackError !== null) {
                            callbackError('request_authorization_error');
                        }
                    });
                }

            }, (error) => {
                if (callbackError !== null) {
                    callbackError('request_authorization_error');
                }
            });
        }
    }, (error) => {
        if (error === 'cordova_not_available') {
            callbackSuccess();
        } else {
            if (callbackError !== null) {
                callbackError(error);
            }
        }
    });
  }

  public watchPosition() {
    this.checkLocalisationStatus(() => {
        this.geolocation.watchPosition({enableHighAccuracy: true, timeout: 10000}).subscribe();
    }, null);
  }

  public getCurrentPosition(callbackSuccess, callbackError) {

    this.checkLocalisationStatus(() => {
        this.geolocation.getCurrentPosition({enableHighAccuracy: true, timeout: 10000, maximumAge: 30000}).then((resp: Geoposition) => {
            callbackSuccess(resp);
        }).catch((error) => {
            callbackError(error);
        });
    }, (error) => {
        callbackError(error);
    });

  }

  public getDistanceBetween(currentPosition = {latitude: null, longitude: null}, distantPosition = {latitude: null, longitude: null}) {
      const R = 6371e3; // metres
      const φ1 = this.convertToRadians(distantPosition.latitude);
      const φ2 = this.convertToRadians(currentPosition.latitude);
      const Δφ = this.convertToRadians(currentPosition.latitude - distantPosition.latitude);
      const Δλ = this.convertToRadians(currentPosition.longitude - distantPosition.longitude);

      const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c;
  }

    private convertToRadians(degrees) {
        // Doc - Converting degree to radians by extending Js Math : http://cwestblog.com/2012/11/12/javascript-degree-and-radian-conversion/
        return degrees * Math.PI / 180;
    }
}

